<template>
    <div class="thumbnails">
      <div class="banner thumbnails__imgWrap">
        <img class="banner__img thumbnails__img" src="../assets/img/banners/wholesale-banner.jpg" alt="">
      </div>
      <div class="thumbnails__textWrap">
        <h1 class="titlePage">Welcome to starline security <br> <strong class="titlePage_accent">wholesale system</strong></h1>
        <span class="text text_green text_center text_italic">Please make a selection...</span>
        <div class="thumbnailsTable thumbnailsTable_list">
          <router-link :to="item.link" class="thumbnailsTable__item thumbnailsTable__item_2" v-for="item in items">
            <img :src="item.img" :alt="item.text" class="thumbnailsTable__img">
            <span class="thumbnailsTable__text">{{item.text}}</span>
          </router-link>
        </div>
        <div class="divider">
          <span class="divider__text text_green">or</span>
        </div>
        <router-link :to="home" class="btn btn_default btn_full">Go back to main screen</router-link>
      </div>
      <router-view></router-view>
    </div>
</template>

<script>
  export default {
    name: "Wholesale",
    data() {
      return {
        home: '/',

        items: [
          {
            img: require('../assets/img/png/account.png'),
            text: 'Sign in',
            link: 'login',
          },
          {
            img: require('../assets/img/png/customer.png'),
            text: 'Become a Wholesale Customer',
            link: 'registration',
          },
          {
            img: require('../assets/img/png/status.png'),
            text: 'Check Application Status',
            link: 'status',
          },
          {
            img: require('../assets/img/png/contact.png'),
            text: 'Contact Starline Security',
            link: '/',
          },
        ],
      };
    },
    computed: {

    },
  };
</script>

<style scoped lang="scss">
  @import '../assets/scss/utils/vars';

  .text{
    display: none;
  }

  @media screen and (max-width: $md){
    .text{
      display: block;
    }
  }

</style>
